import { configureStore } from '@reduxjs/toolkit';
// import searchFilterReducer from './searchFilterSlice';
import searchResultPageReducer from './searchResultPageSlice';
import userMetaDataReducer from './userMetaDataSlice';
import redirectPageReducer from './redirectPageSlice';

const store = configureStore({
	reducer: {
		// searchFilter: searchFilterReducer,
		searchResultPage: searchResultPageReducer,
		userMetaData: userMetaDataReducer,
		redirectPage: redirectPageReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}),
});


export default store;
export type RootState = ReturnType<typeof store.getState>;