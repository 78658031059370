import { Flex, Text } from "@mantine/core";
import { Fonts, RawText, howDoesItWorksCardsData } from "../../models";
import { CardJrmLight } from "../cardJrmLight/cardJrmLight.component";

export function HowItWorks() {
    return (
        <Flex
            direction={"column"}
            w={"100%"}
            justify={"center"}
            align={"center"}
        >
            <Text
                style={{ fontFamily: Fonts.NEWSREADER, fontSize: 48 }}
            >
                {RawText.HOW_IT_WORKS}
            </Text>

            <Flex
                justify={"center"}
                align={"center"}
                w={"100%"}
                wrap={"wrap"}
            >
                {howDoesItWorksCardsData.map((data: any) =>
                    <CardJrmLight
                        h={400}
                        icon={data.icon}
                        title={data.title}
                        description={data.description}
                    />)}
            </Flex>
        </Flex>
    )
}