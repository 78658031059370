import { Anchor, Button, Flex, Loader, rem, Text } from "@mantine/core";
import { Fonts } from "../../models";
import { IconArrowGuide } from "@tabler/icons-react";
import { useEffect } from "react";
import { Job } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export function RedirectExternalPage() {

    const redirectionJob: Job | null = useSelector((state: RootState) => state.redirectPage.job);

    useEffect(() => {
        if (redirectionJob) {
            setTimeout(async () => {
                window.location.href = redirectionJob.application_url;
            }, 2000);
        }
    }, []);

    return (
        <Flex align={"center"} justify={"center"} p={48} direction={"column"} h={700}>
            <IconArrowGuide style={{ width: rem(48), height: rem(48) }} />
            <Text fw={500} w={600} ta={"center"} style={{ fontSize: 40, fontFamily: Fonts.NEWSREADER }}>You’re being redirected to complete the application for</Text>
            <Text fw={500} size="xl" ta={"justify"} c={"#4d4d4d"}>{redirectionJob?.title}</Text>
            <Text size="md" c={"#4d4d4d"} style={{ fontFamily: Fonts.INTER }} w={400} ta={"justify"} my={8}>
                We’re taking you to a trusted partner page to complete your job application. It will automatically redirect in 2 seconds.
                If redirection doesn't happen, click continue to proceed.
            </Text>
            <Loader type="bars" my={8} />
            {redirectionJob?.application_url.length ?
                <Anchor href={redirectionJob?.application_url} c={"#ffffff"} size="sm" fw={600}>
                    <Button color="black" w={300} my={8}>
                        Continue
                    </Button>
                </Anchor>
                :
                <></>
            }
        </Flex>
    );
}