import { Anchor, Button, Flex, Select, Space, Text } from "@mantine/core";
import { HomepageStats, DemandingCategories, HowItWorks, SearchFilterComponent } from "../../components";
import { ReactComponent as IconArrowDown } from '../../assets/iconArrowDown.svg';
import { AppRoutes, Fonts, RawText } from "../../models";
import styles from './homepage.module.css';
import { useMediaQuery } from "@mantine/hooks";
import { LargeJobCardComponent } from "../../components/largeJobCard/largeJobCard.component";
import { useEffect, useState } from "react";
import { Country, EmployerCountByCategory, JobCountByCategory } from "../../types";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { JobService } from "../../services/jobsService";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams } from "react-router-dom";

export function Homepage() {

    const isDesktop = useMediaQuery('(min-width: 1285px)');

    const [demaingCategories, setDemandingCategories] = useState<JobCountByCategory[]>([]);
    const [employerCountByCategory, setEmployerCountByCategory] = useState<EmployerCountByCategory[]>([]);
    const [employers, setEmployers] = useState<string[]>([]);
    const [allJobsCount, setAllJobsCount] = useState<number>();
    const [allEmployersCount, setAllEmployersCount] = useState<number>();
    const [allIndustryCount, setAllIndustryCount] = useState<number>();

    const clientCountry: Country | null = useSelector((state: RootState) => state.userMetaData.clientCountry);
    const jobService: JobService = new JobService();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        initHomepage();
    }, [isDesktop]);

    async function initHomepage() {
        if (clientCountry) {

            const jobsCount = await jobService.findAllJobsCount();
            setAllJobsCount(jobsCount);

            const employersCount = await jobService.findAllEmployersCount();
            setAllEmployersCount(employersCount);

            const industryCount = await jobService.findAllIndustryCount();
            setAllIndustryCount(industryCount);

            const employerCountByCategory: EmployerCountByCategory[] = await jobService.getEmployerCountByCategory(clientCountry.countryName);
            setEmployerCountByCategory(employerCountByCategory);

            const employersByCountry: string[] = await jobService.findEmployersByCountry(clientCountry.countryName);
            setEmployers(employersByCountry);

            const demaingCategories: JobCountByCategory[] = await jobService.getJobCountByCategory(clientCountry.countryName);
            setDemandingCategories(demaingCategories.splice(0, isDesktop ? 6 : 3));
        }

    }

    async function searchByEmployer() {
        navigate(AppRoutes.JOB_SEARCH);
        setSearchParams({
            employers: [searchByEmployerForm.getTransformedValues().employer]
        });
    }

    const searchByEmployerForm = useForm({
        initialValues: {
            employer: "",
        },
        validate: {
            employer: (value) => value.length ? null : "Select a valid employer"
        }
    })

    return (
        <>
            <Flex py={100} justify={"center"} align={"center"} direction={"column"} w={"100%"}>
                <Flex w={"70%"} ff={Fonts.NEWSREADER} >
                    <Text className={styles.heading} ta={"center"}>
                        {RawText.HOME_PAGE_DESC}
                    </Text>
                </Flex>
                <SearchFilterComponent />

                <Flex>
                    <Text c={"dimmed"}>
                        {RawText.LOOKING_FOR_CANDIDATE} &nbsp;
                    </Text>
                    <Anchor href="#" target="_blank" underline="hover" c={"black"} fw={"bold"}>
                        {RawText.POST_A_JOB}
                    </Anchor>
                </Flex>

                <Space h={40} />
                <Flex onClick={() => window.scroll({ top: 900, left: 0, behavior: "smooth" })} style={{ cursor: "pointer" }}>
                    <IconArrowDown className={styles.animateIcon} />
                </Flex>

                <Space h={40} />
                {allJobsCount && allEmployersCount && allIndustryCount &&
                    <HomepageStats allJobsCount={allJobsCount} allEmployersCount={allEmployersCount}  allIndustryCount={allIndustryCount}/>
                }

                <Flex my={50} w={"70%"}>
                    <DemandingCategories data={demaingCategories} />
                </Flex>

                <Flex my={50} w={isDesktop ? "70%" : "80%"} direction={"column"}>
                    {demaingCategories.map((item: JobCountByCategory) =>
                        <>
                            <LargeJobCardComponent data={item} employerCount={employerCountByCategory.find((employerCount: EmployerCountByCategory) => employerCount.category === item.category)} />
                            <Space h={32} />
                        </>
                    )}
                </Flex>

                <Flex my={50} w={"100%"} className={styles.hiddenOnMobile}>
                    <HowItWorks />
                </Flex>

                <Flex direction={"column"} w={"100%"}>
                    <Flex justify={"center"}>
                        <Text className={styles.heading} ff={Fonts.NEWSREADER} my={24}>Search by Employer</Text>
                    </Flex>
                    <Flex justify={"center"} w={"100%"}>
                        <form style={{ width: "100%", display: "flex", justifyContent: "center" }} onSubmit={searchByEmployerForm.onSubmit(searchByEmployer)}>
                            <Select
                                placeholder="Search by Company"
                                w={isDesktop ? "40%" : "60%"}
                                mr={14}
                                data={employers}
                                searchable
                                {...searchByEmployerForm.getInputProps("employer")}
                            />
                            <Button bg={"black"} type="submit">Search</Button>
                        </form>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}