import { Flex, Text } from "@mantine/core";
import CountUp from 'react-countup';
import { Fonts } from "../../models";

export function HomepageStats(props: any) {

    const allJobsCount: number = props.allJobsCount ? props.allJobsCount : 0;
    const allEmployersCount: number = props.allEmployersCount ? props.allEmployersCount : 0;
    const allIndustryCount: number = props.allIndustryCount ? props.allIndustryCount : 0;

    return (
        <Flex
            align={"center"}
            justify={"space-around"}
            w={"70%"}
            style={{ fontFamily: "Inter" }}
            wrap={"wrap"}
        >
            <Flex
                direction={"column"}
                justify={"center"}
                align={"center"}
                m={15}
            >
                <CountUp end={allJobsCount} duration={2} style={{fontSize: "36px", fontFamily: Fonts.INTER, fontWeight: 600}} />
                <Text size="xs">Openings</Text>
            </Flex>
            <Flex direction={"column"} justify={"center"} align={"center"} m={15}>
                <CountUp end={allEmployersCount} duration={2} style={{fontSize: "36px", fontFamily: Fonts.INTER, fontWeight: 600}} />
                <Text size="xs">Companies</Text>
            </Flex>
            <Flex direction={"column"} justify={"center"} align={"center"} m={15}>
                <CountUp end={allIndustryCount} duration={2} style={{fontSize: "36px", fontFamily: Fonts.INTER, fontWeight: 600}} />
                <Text size="xs">Industies/Categories</Text>
            </Flex>
        </Flex>
    );
}