import { Anchor, Card, Flex, rem, Space, Text } from "@mantine/core";
import { IconBriefcaseFilled, IconMoodHappyFilled, IconGiftFilled } from "@tabler/icons-react";
import { AppRoutes, Fonts, MAX_SALARY, MIN_SALARY } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { Country, EmployerCountByCategory, Job, JobCountByCategory, SearchFilterQuery } from "../../types";
import { JobService } from "../../services/jobsService";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StringUtils } from "../../utils";
import { SmallJobCard } from "../smallJobCard/smallJobCard.component";

export function LargeJobCardComponent(props: any) {

    const matches = useMediaQuery('(min-width: 1285px)');
    const data: JobCountByCategory = props.data;
    const employerCount: EmployerCountByCategory = props.employerCount;

    const jobsService: JobService = new JobService();
    const [jobs, setJobs] = useState<Job[]>([]);
    const clientCountry: Country | null = useSelector((state: RootState) => state.userMetaData.clientCountry);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (clientCountry) {
            fetchJobsByCategory();
        }
    }, [data]);


    async function fetchJobsByCategory() {
        const searchFilterQuery: SearchFilterQuery = {
            keyword: "",
            countries: [clientCountry?.countryName as string],
            regions: [],
            cities: [],
            categories: [data.category],
            employers: [],
            salaryMinimum: MIN_SALARY,
            salaryMaximum: MAX_SALARY,
            radius: 0,
            latitudeAnchor: 0,
            longitudeAnchor: 0,
            distanceUnit: "",
            employmentTypes: [],
            ageUnixTimestamp: 0,
            limit: 3,
            offset: 0
        };

        const { jobs, count } = await jobsService.findJobs(searchFilterQuery);
        setJobs(jobs);
        console.log(jobs, count);
    }

    async function navigateToMoreJobs() {
        navigate(AppRoutes.JOB_SEARCH);
        setSearchParams({
            categories: data.category
        })

    }

    return (
        <Card bg={"#f9f9f9"} w={"100%"} p={32} style={{ borderRadius: 8 }}>
            <Flex justify={matches ? "space-between" : "center"} align={"center"} wrap={matches ? "inherit" : "wrap"} m={8}>
                <Text style={{ fontSize: 32 }} fw={600} ff={Fonts.NEWSREADER} m={8}>{data.category}</Text>
                <Anchor c={"black"}>
                    <Text size="md" fw={600} onClick={() => navigateToMoreJobs()}>Explore more jobs</Text>
                </Anchor>
            </Flex>

            <Flex justify={"space-between"} w={"100%"} wrap={matches ? "inherit" : "wrap"}>
                <Flex direction={"column"} w={matches ? "30%" : "100%"} wrap={matches ? "inherit" : "wrap"} justify={"center"}>
                    <Flex align={"center"} m={4}>
                        <IconBriefcaseFilled color="#4d4d4d" style={{ marginRight: 8 }} />
                        <Text size="sm" c={"#4d4d4d"} ml={4} fw={600}>{StringUtils.formatNumber(data.job_count)} Jobs</Text>
                    </Flex>
                    <Flex align={"center"} m={4}>
                        <IconMoodHappyFilled color="#4d4d4d" style={{ marginRight: 8 }} />
                        <Text size="sm" c={"#4d4d4d"} fw={600} >{StringUtils.formatNumber(employerCount.employer_count)} Employers</Text>
                    </Flex>
                </Flex>
                <Flex justify={"space-between"} w={"100%"} wrap={matches ? "inherit" : "wrap"}>
                    {jobs.map((job: Job) =>
                        <SmallJobCard job={job} />
                    )}
                </Flex>
            </Flex>
        </Card>
    )
}