import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export class DateUtils {

    

    // static timeAgo(unixTimestamp: number): string {
    //     const seconds = Math.floor((new Date().getTime() - unixTimestamp * 1000) / 1000);

    //     let interval = Math.floor(seconds / 31536000);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " year ago" : " years ago");
    //     }

    //     interval = Math.floor(seconds / 2592000);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " month ago" : " months ago");
    //     }

    //     interval = Math.floor(seconds / 604800);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " week ago" : " weeks ago");
    //     }

    //     interval = Math.floor(seconds / 86400);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " day ago" : " days ago");
    //     }

    //     interval = Math.floor(seconds / 3600);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " hour ago" : " hours ago");
    //     }

    //     interval = Math.floor(seconds / 60);
    //     if (interval >= 1) {
    //         return interval + (interval === 1 ? " minute ago" : " minutes ago");
    //     }

    //     return seconds + (seconds === 1 ? " second ago" : " seconds ago");
    // }

    static getTimeFromNow(dateString: string): string {
        return dayjs(dateString).fromNow();
    }
}