import { Flex, Space, Text } from "@mantine/core";

export function CardJrmLight(props: any) {

    return (
        <Flex
            direction={"column"}
            p={props.p ? props.p : 8}
            bg={"#F9F9F9"}
            align={"center"}
            justify={"center"}
            h={props.h}
            m={6}
            style={{borderRadius: "8px"}}
        >
            <Flex mt={""}>
                {props.icon && <props.icon />}
            </Flex>
            <Space h={30} />
            <Text size="xl" fw={"bold"}>{props.title ? props.title : ""}</Text>
            <Text c={"dimmed"} size="xs" ta={"center"}>{props.description ? props.description : ""}</Text>
        </Flex>
    )
}