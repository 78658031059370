import { useRef, useState } from 'react';
import { CheckIcon, Combobox, Flex, Group, Input, InputBase, ScrollArea, Text, useCombobox } from '@mantine/core';

export function MaxDisplayedItemsDropdown(props: any) {

  const items: string[] = props.items ? props.items : [];
  const label: string = props.label ? props.label : "";
  const width: number = props.width ? props.width : 300;
  const placeholder: string = props.placeholder ? props.placeholder : "";
  const defaultValues: string[] = props.defaultValues ? props.defaultValues: [];

  const viewportRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const onValueChange: (values: string[]) => {} = props.onValueChange ? props.onValueChange : (values: string[]) => { };

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
    },
    onDropdownOpen: () => {
      (searchInputRef.current as HTMLInputElement).focus();
      combobox.updateSelectedOptionIndex('active');
    },
  });

  const [value, setValue] = useState<string[]>(defaultValues);
  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => {
    setValue((current) =>
      current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
    );

    const updatedValues = value.includes(val) ? value.filter((v) => v !== val) : [...value, val];
    onValueChange(updatedValues);
  }

  function getFilteredOptions(data: string[], searchQuery: string, limit: number) {
    const result: string[] = [];

    for (let i = 0; i < data.length; i += 1) {
      if (result.length === limit) {
        break;
      }

      if (data[i].toLowerCase().includes(searchQuery.trim().toLowerCase())) {
        result.push(data[i]);
      }
    }

    for (let i = 0; i < value.length; i++) {
      const index = result.indexOf(value[i]);
      if (index > -1) {
        result.splice(index, 1);
      }
      result.unshift(value[i]);
    }

    return result;
  }

  const options = getFilteredOptions(items, search, 150)
    .map((item) => (
      <Combobox.Option value={item} key={item} active={value.includes(item)} w={width}>
        <Group gap="md">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));


  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false} width={width}>
      <Combobox.DropdownTarget>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          w={width}
          size='md'
        >
          {value.length > 0 ?
            <Flex w={width}>
              <Text fw={500} c={"#4d4d4d"} size='md'>{label} ({value.length})</Text>
            </Flex> :

            <Input.Placeholder>{label}</Input.Placeholder>}
        </InputBase>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown w={300} autoFocus>
        <Input
          size="sm"
          placeholder={placeholder}
          onChange={(event) => setSearch(event.currentTarget.value)}
          variant={"filled"}
          type="text"
          ref={searchInputRef}
        />

        <Combobox.Options onMouseOver={() => searchInputRef.current?.focus()}>
          <ScrollArea.Autosize viewportRef={viewportRef} mah={300} scrollbarSize={3}>
            {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}