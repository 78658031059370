import { Flex, rem, Space, Text } from "@mantine/core";
import { IconGiftFilled } from "@tabler/icons-react";
import { AppRoutes, Fonts } from "../../models";
import { Job } from "../../types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function SmallJobCard(props: any) {

    const job: Job = props.job;
    const [hover, setHover] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <Flex style={{ border: `1px solid #cfcfcf`, borderRadius: 8, cursor: "pointer" }}
            p={32} m={8} w={"100%"} align={"center"} h={200} bg={hover ? "#ffffff" : ""}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => navigate(`${AppRoutes.JOB}/${job.jobsrmine_url}`)}
        >
            <IconGiftFilled style={{ width: rem(64), height: rem(64) }} />
            <Space w={12} />
            <Flex direction={"column"} w={"100%"}>
                <Text size="md" fw={600} c={"#4d4d4d"} ff={Fonts.INTER}>{job.title}</Text>
                <Text size="xs" c="#4d4d4d" ff={Fonts.INTER}>{job.city}</Text>
            </Flex>
        </Flex>
    );
}