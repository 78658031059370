import { Anchor, Flex, Space, Text } from "@mantine/core";
import { MostPopularIndustryCard } from "../mostPopularIndustryCard/mostPopularIndustryCard";
import { Fonts, RawText } from "../../models";
import styles from '../../pages/homepage/homepage.module.css';
import { JobCountByCategory } from "../../types";

export function DemandingCategories(props: any) {

    const demaingCategories = props.data;

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"} align={"center"} mt={50}>
            <Text className={styles.heading} style={{ fontFamily: Fonts.NEWSREADER, fontSize: 48 }}>
                {RawText.MOST_POPULAR_INDUSTRIES}
            </Text>

            <Flex justify={"center"} align={"center"} w={"100%"} wrap={"wrap"}>
                {demaingCategories.map((item: JobCountByCategory) => <MostPopularIndustryCard label={item.category} count={item.job_count} />)}
            </Flex>
            <Space h={30} />
            <Anchor href="#" target="_blank" underline="hover" c={"black"} fw={"bold"}>
                {RawText.EXPLORE_ALL_INDUSTRIES}
            </Anchor>
        </Flex>
    );
}