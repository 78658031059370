export const navbarLinks = [
    {
        title: "CANDIDATES",
        href: "#",
        subMenuItems: [
            {
                title: "UPLOAD CV",
                href: "#",
            },
            {
                title: "EASY SEARCH",
                href: "#",
            },
            {
                title: "VISA SPONSORSHIP JOBS",
                href: "#",
            },
            {
                title: "FREE JOB ALERTS",
                href: "#",
            },
        ]
    },
    {
        title: "ADVERTISERS",
        href: "#",
        subMenuItems: [
            {
                title: "POST JOB",
                href: "#",
            },
        ]
    },
    {
        title: "GET AI HELP",
        href: "#",
    },
    {
        title: "RESOURCES",
        href: "#",
    },
    {
        title: "BLOG",
        href: "#",
    },
    {
        title: "CONTACT",
        href: "#",
    }
];